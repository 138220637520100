import showListItem from "../show-list-item"

export const showTasksDashboard = (features, showDetails, activeTaskIndex, activeEpicIndex, selectedTasks, setSelectedTasks, setIsAllTaskSelected) => {
    let Tasks = []

    features.forEach(feature => {
        feature.tasks.forEach(task => {
            Tasks.push(task)
        })
    })

    const handleOnchangeCheckbox = (task, isChecked) => {
        if(isChecked) {
            const tempSelectedTasks = {...selectedTasks}
            delete tempSelectedTasks[task.uuid]

            setSelectedTasks(tempSelectedTasks)
            setIsAllTaskSelected(false)
            return 
        }

        if(!isChecked) {
            setSelectedTasks({
                ...selectedTasks,
                [task.uuid] : task
            })
        }
    }

    return Tasks.map((task, index) => (
        <TaskWithSelectionCheckBox key={index} index={index} showDetails={showDetails} task={task} activeTaskIndex={activeTaskIndex} activeEpicIndex={activeEpicIndex}>
            {
                /*
                TODO: Disable Checkbox for already created Tasks
                */
            }
            <div>
                <input onChange={() => handleOnchangeCheckbox(task, selectedTasks[task.uuid] !== undefined)} checked={selectedTasks[task.uuid] !== undefined} id="task-selection" type="checkbox" className="h-6 w-6 accent-pink-300 md:accent-pink-500"/>
            </div>
        </TaskWithSelectionCheckBox>
    ))
}

export const showTasksViewFeedback = (features, showDetails, activeTaskIndex, activeEpicIndex) => {
    let Tasks = []

    features.forEach(feature => {
        
        feature.tasks.forEach(task => {
            Tasks.push(task)
            task?.subtasks?.forEach(subtask => {
                Tasks.push(subtask)
            })
        })
    })

    return Tasks.map((task, index) => <Task key={index} index={index} showDetails={showDetails} task={task} activeTaskIndex={activeTaskIndex} activeEpicIndex={activeEpicIndex}/>)
}

const Task = ({index, showDetails, task, activeTaskIndex, activeEpicIndex}) => {
    const {task_name, description, acceptance_criteria} = task

    const handleClick = () => {
        showDetails(task, index)
    }

    const backgroundColor = activeTaskIndex === index ? '#f2f2f2' : '#FAFAFA'
    const borderColor = activeTaskIndex === index ? '#ff5c00' : '#0D61F7'
    const divBorderColor = activeTaskIndex === index ? '#ff5c00' : '#E3E3E3'

    return (
    <div role="button" style={{backgroundColor:backgroundColor, borderColor: divBorderColor, transition: 'border-color .3s ease-out'}} className="shadow-lg min-w-80 max-w-80 border pt-4" onClick={handleClick}>
        <div className="mx-3 flex items-center justify-start mb-3 gap-2">
            <div>
                <div className="bg-[#EAD9F8] text-[#913CB9] text-xs font-bold px-2 py-1 rounded">{Number(activeEpicIndex + 1) + '.' + Number(index + 1)}</div>
            </div>

        </div>

        <div style={{borderColor: borderColor, transition: 'border-color .3s ease-out'}} className="border-l-4 px-3 flex flex-col gap-1">
            <div className="font-medium text-base truncate">{task_name}</div>
            <div className="font-normal text-xs text-[#828282] truncate">{description}</div>
        </div>

        {(acceptance_criteria && acceptance_criteria.length > 0) && (<div className="px-4 pt-4">
            <div className="font-medium text-xs text-[#828282]">Acceptance Criteria:</div>
            <ol className="text-xs list-item">
                <li className="truncate">1. {Array.isArray(acceptance_criteria) ? acceptance_criteria[0] : acceptance_criteria}</li>
            </ol>
        </div>)}

        <div className="border-t-[#E3E3E3] border-t mx-3 mt-3 flex items-center justify-end min-h-11">
        </div>
    </div>
    )
}

export const TaskWithSelectionCheckBox = ({index, showDetails, task, activeTaskIndex, activeEpicIndex, children}) => {
    const {task_name, description, acceptance_criteria} = task

    const handleClick = (e) => {
        if(e.target?.id === 'task-selection') { return }
        showDetails(task, index)
    }

    const backgroundColor = activeTaskIndex === index ? '#f2f2f2' : '#FAFAFA'
    const borderColor = activeTaskIndex === index ? '#ff5c00' : '#0D61F7'
    const divBorderColor = activeTaskIndex === index ? '#ff5c00' : '#E3E3E3'

    return (
    <div role="button" style={{backgroundColor:backgroundColor, borderColor: divBorderColor, transition: 'border-color .3s ease-out'}} className="shadow-lg min-w-80 max-w-80 border pt-4" onClick={handleClick}>
        <div className="mx-3 flex justify-start mb-3 gap-2">
            {children}
            <div>
                <div className="bg-[#EAD9F8] text-[#913CB9] text-xs font-bold px-2 py-1 rounded">{Number(activeEpicIndex + 1) + '.' + Number(index + 1)}</div>
            </div>

        </div>

        <div style={{borderColor: borderColor, transition: 'border-color .3s ease-out'}} className="border-l-4 px-3 flex flex-col gap-1">
            <div className="font-medium text-base truncate">{task_name}</div>
            <div className="font-normal text-xs text-[#828282] truncate">{description}</div>
        </div>

        {(acceptance_criteria && acceptance_criteria.length > 0) && (<div className="px-4 pt-4">
            <div className="font-medium text-xs text-[#828282]">Acceptance Criteria:</div>
            <ol className="text-xs list-item">
                <li className="truncate">1. {Array.isArray(acceptance_criteria) ? acceptance_criteria[0] : acceptance_criteria}</li>
            </ol>
        </div>)}

        <div className="border-t-[#E3E3E3] border-t mx-3 mt-3 flex items-center justify-end min-h-11">
        </div>
    </div>
    )
}

  export const TaskDetails = ({ticket, activeTaskIndex, activeEpicIndex}) => {
    const {task_name, description, acceptance_criteria, suggestions, epic, feature, is_external_task, is_task, is_sub_task, id, dependencies} = ticket

    return (            

              <div className="bg-white text-left shadow-xl transition-all">
                
                <div className="bg-white pt-1">
                <div className="flex items-center justify-between"> 
                <div className="flex items-center justify-start gap-2 pl-3">
                    <div className="bg-[#EAD9F8] text-[#913CB9] text-xs font-bold px-2 py-1 rounded">{Number(activeEpicIndex + 1) + '.' + Number(activeTaskIndex + 1)}</div>
                
                    {is_task && <div className="ml-3 bg-[#ffece1] text-[#ff5c00] text-xs font-bold px-2 py-1 rounded">Task</div>}
        
                    {is_sub_task && <div className="ml-3 bg-[#ffece1] text-[#ff5c00] text-xs font-bold px-2 py-1 rounded">Sub-Task</div>}
        
                    {epic && <div className="bg-[#FFECE1] text-[#FF5C00] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Epic</div> */}
                        <div className="text-xs font-bold truncate">{epic}</div>
                    </div>}
        
                    {feature && <div className="bg-[#D9F4F8] text-[#268FB0] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Feature</div> */}
                        <div className="text-xs font-bold">{feature}</div>
                    </div>}
        
                    {is_external_task && <div className="bg-[#D9F4F8] text-[#268FB0] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Feature</div> */}
                        <div className="text-xs font-bold">External Task</div>
                    </div>}
        
                    
                </div>
                </div>
        
                <div className="border-l-4 border-[#ff5c00] px-3">
                    <div className="font-medium text-lg">{task_name}</div>
                    <div className="font-light text-sm text-[#828282]">{description}</div>
                </div>
        
                {(acceptance_criteria && acceptance_criteria.length > 0) && <div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Acceptance Criteria:</div>
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showListItem(acceptance_criteria)}
                    </ol>
                </div>}
        
                {(suggestions && suggestions.length > 0) && (<div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Suggestions:</div>
                    <ul className="pl-4 text-sm font-light list-decimal">
                        {showListItem(suggestions)}
                    </ul>
                </div>)}
                
                <div className="bg-gray-50 min-h-12">
                    <div className="border-t-[#E3E3E3] border-t mx-3 mt-3 flex items-center justify-between ">
                    </div>   
                </div>
                </div>
        
              </div>          
)
}


  export default Task