import { useCallback, useEffect, useMemo, useState } from 'react';
import UploadIcon from '../../assets/upload.svg'
import PdfIcon from '../../assets/pdf.svg'
import CloseIcon from '../../assets/close.svg'
import {  toast } from 'react-toastify';
import {useDropzone} from 'react-dropzone'
import AxiosInstance from '../../lib/axios/axios-instance';
import { useNavigate } from 'react-router-dom';
import { showTasks, showTasksDashboard, TaskDetails, TaskDetailsWithJiraButton, TaskWithSelectionCheckBox } from '../../components/task';
import { showEpics } from '../../components/epic';
import NoContentContainer from '../../components/no-content-container';
import { LLMs, FEEDBACK_TYPES } from '../../types'
import { getFragmentaryResponse } from '../../components/fragmentary-response';
import { useSelector } from 'react-redux';
import CheckResultMessage from '../../components/check-result-message';
import Modal, { ModalComponentWrapper } from '../../components/modal';


const baseStyle = {
    flex: 1,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundcolor: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
  
  const focusedStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#0D61F7'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };



const AdminRoutes = () => {
    const authUser = useSelector((store) => store.authUser);
    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path)
    }


    if(authUser.userType === "admin") {
        return (
            <button  onClick={()=>navigateTo('/admin')} className="bg-transparent border border-black rounded px-6 py-2 text-black disabled:bg-[#f3f3f4] disabled:text-[#8D8A95] hover:bg-[#0D61F7] hover:border-[#0D61F7] hover:text-white">
                Admin
            </button>
        )
    }

    return null
}

const getOptions = () => {
    return LLMs.map((LLM) => <option key={LLM.name} value={LLM.value}>{LLM.name}</option>)
}

export const ModalComponent = ({
    baseUrl,
    token,
    email,
    projectKey,
    setEmail,
    setBaseUrl,
    setProjectKey,
    setToken,
    submitJiraAuthDetails,
    closeModal,
    isSubmitting
}) => {
    return(
        <>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Jira Auth Details</h3>
                    <div className="flex flex-col gap-5 mt-5">
                        <div>
                            <div>
                                <label className="text-sm text-gray-500">Base URL:</label>
                            </div>
                            <div>
                                <input value={baseUrl} onChange={(e)=>setBaseUrl(e.target.value)} className="w-full text-sm font-light border-b focus:border-[#FF5C00]" type="text" placeholder="base url" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <label className="text-sm text-gray-500">Email:</label>
                            </div>
                            <div>
                                <input value={email} onChange={(e)=>setEmail(e.target.value)} className="w-full text-sm font-light border-b focus:border-[#FF5C00]" type="text" placeholder="email" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <label className="text-sm text-gray-500">Token:</label>
                            </div>
                            <div>
                                <input value={token} onChange={(e)=>setToken(e.target.value)} className="w-full text-sm font-light border-b focus:border-[#FF5C00]" type="text" placeholder="token" />
                            </div>
                        </div>

                        <div>
                            <div>
                                <label className="text-sm text-gray-500">Project Key:</label>
                            </div>
                            <div>
                                <input value={projectKey} onChange={(e)=>setProjectKey(e.target.value)} className="w-full text-sm font-light border-b focus:border-[#FF5C00]" type="text" placeholder="project key" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
            <button onClick={submitJiraAuthDetails} disabled={!email || !token || !baseUrl || !projectKey || isSubmitting} className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto">{isSubmitting ? 'Submitting...' : 'Submit'}</button>
            <button onClick={closeModal} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
        </>
    )
}


const AddToJiraButton = ({isAllTaskSelected, selectedTasks, isMissingAuthDetails, epicDetails, projectName}) => {
    const axiosInstance = AxiosInstance.getInstance()
    const hasTaskSelected = Object.values(selectedTasks).length !== 0
    const [isAdding, setIsAdding] = useState(false)
    

    // TODO: Update the Epic on Database with epicKey and Tasks on Database with the respective Tasks Key
    const onClick = async () => {
        if(!hasTaskSelected) return
        if(isMissingAuthDetails) {
            return toast.warning('Please check the Jira Auth Details before proceeding to create Tickets in the Jira.')
        }
        setIsAdding(true)
        try {
            const epicResponse = await axiosInstance.post("/epic",
                {
                    epic_name: epicDetails.epic_name,
                    epic_description: epicDetails.epic_description
                }, 
                {
                params: {
                    project_name: projectName
            }})


            const tickets = []
            Object.values(selectedTasks).forEach((task) => {
                // TODO: filter all the task that has been created, earlier.
                tickets.push({
                    acceptance_criteria: task.acceptance_criteria,
                    description: task.description,
                    task_name: task.task_name
                  })
            })

            const epicKey = epicResponse.data.key // TODO: Epic Key that needs to be stored
            const chunkSize = 50; // Only 50 Tasks can be created at a time
            for (let i = 0; i < tickets.length; i += chunkSize) {
                const chunk = tickets.slice(i, i + chunkSize);

                // TODO: Created Task Response from Jira: issues[index].key is Task Key that needs to be stored
                const taskTicketsResponse = await axiosInstance.post("/ticket/bulk",
                    {
                        tickets: chunk
                    }, 
                    {
                    params: {
                        project_name: projectName,
                        issue_type: "Task",
                        epic_key: epicKey
                }})
            }
            


            toast.success("All Tickets added to Jira Successfully.")
            setIsAdding(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setIsAdding(false)
        }
    }
    return (
        <div className="pt-3 pb-2">
            <button disabled={!hasTaskSelected || isAdding} onClick={onClick} className="bg-[#CDF4DD] text-[#188544] text-sm font-bold px-2 py-1 rounded disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">Add {isAllTaskSelected ? 'All Tasks' : 'Task(s)'} to Jira</button>
        </div>
    )
}


const Dashboard = () => {
    const authUser = useSelector((store) => store.authUser);
    const axiosInstance = AxiosInstance.getInstance()

    const [file, setFile] = useState(null)
    const [projectName, setProjectName] = useState('')
    const [LLM, setLLM] = useState(LLMs[0].value)

    const [uploading, setUploading] = useState(false)
    const [checkingResult, setCheckingResult] = useState(false)
    const [generating, setGenerating] = useState(false)

    const [fragmentaryResponse, setFragmentaryResponse] = useState(null)
    const [fragmentaryResponseProjectName, setFragmentaryResponseProjectName] = useState(null)
    const [epics, setEpics] = useState(null)
    const [activeEpicIndex, setActiveEpicIndex] = useState(-1)
    const [features, setFeatures] = useState(null)
    const [tasksLength, setTasksLength] = useState(0)
    const [activeTaskIndex, setActiveTaskIndex] = useState(-1)
    const [details, setDetails] = useState(null)

    const [epicFeedbackPointers, setEpicFeedbackPointers] = useState(null)
    const [taskFeedbackPointers, setTaskFeedbackPointers] = useState(null)

    const [showJiraAuthDetailsModal, setShowJiraAuthDetailsModal] = useState(false)

    const [email, setEmail] = useState('')
    const [token, setToken] = useState('')
    const [baseUrl, setBaseUrl] = useState('')
    const [projectNameJira, setProjectNameJira] = useState('')
    const [projectKey, setProjectKey] = useState('')

    const [isSubmittingJiraAuthDetails, setIsSubmittingJiraAuthDetails] = useState(false)

    const [selectedTasks, setSelectedTasks] = useState({})
    const [isAllTaskSelected, setIsAllTaskSelected] = useState(false)

    const onDrop = useCallback(acceptedFiles => {
        if(!acceptedFiles?.length) return
            
        setFile(acceptedFiles[0])
    }, [])

    const {getRootProps, getInputProps, isFocused,
        isDragAccept,
        isDragReject} = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf']
        },
        maxFiles: 1
    })


    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    const onRemoveFile = () => {
        setFile(null)
        setFragmentaryResponse(null)
        setFragmentaryResponseProjectName(null)
    }

    const handleUploadFile = async () => {
        try {
            setUploading(true)
            const form = new FormData();
            form.append('file', file);

            const response = await axiosInstance.post("/upload",form, {params: {
                project_name: projectName,
                llm_type: LLM
            }})
            
            toast.success("Pdf uploaded and saved successfully.")
            setFragmentaryResponse(response?.data?.fragmentary_response)
            setFragmentaryResponseProjectName(projectName)
            setUploading(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setUploading(false)
        } 
    }


    const checkResult = async () => {
        setEpics(null)
        try {
            setCheckingResult(true)
            const response = await axiosInstance.get("/check-result", {
                params: {
                    project_name: projectName,
                    llm_type: LLM
                }
            })
            
            if(response?.data?.length === 0 ) {
                toast.error(`No result found for the Project Name: ${projectName}`)
                setCheckingResult(false)
                return
            }
            
            if(Array.isArray(response?.data)) {
                setEpics(response?.data)
            } else {
                if(response?.data?.status === 'success') {  toast.success(<CheckResultMessage />)  }
                if(response?.data?.status === 'failed') {   toast.error(<CheckResultMessage status={response?.data?.status} message={response?.data?.message}/>)  }
            }
       
            setCheckingResult(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setCheckingResult(false)
        } 
    }

    const generate = async () => {
        try {
            setGenerating(true)
            const response = await axiosInstance.get("/generate/tickets", {
                params: {
                    project_name: projectName,
                    llm_type: LLM
                }
            })

            toast.success(response?.data || "Ticket Generation will proceed in Background.")
            setGenerating(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setGenerating(false)
        } 
    }

    const navigate = useNavigate();

    const navigateTo = (path) => {
        navigate(path)
    }

    const addTask = (index) => {
        setActiveEpicIndex(-1);
        setFeatures(null)
        setActiveTaskIndex(-1)
        setDetails(null)


        setActiveEpicIndex(index)
        const epic = epics[index]

        setFeatures(epic.features)

        setIsAllTaskSelected(false)
        setSelectedTasks({})
    }

    const showDetails = (taskDetails, index) => {
        setActiveTaskIndex(index)
        setDetails(taskDetails)
    }


    const getTaskFeedbackPointers = async () => {
        try {
            const response = await axiosInstance.get("/feedback-pointer/filter", {
                params: {
                    type: FEEDBACK_TYPES[1]
                }
            })

            setTaskFeedbackPointers(response?.data)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
        } 
    }

    const getEpicFeedbackPointers = async () => {
        try {
            const response = await axiosInstance.get("/feedback-pointer/filter", {
                params: {
                    type: FEEDBACK_TYPES[0]
                }
            })

            setEpicFeedbackPointers(response?.data)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
        } 
    }

    useEffect(() => {
        getEpicFeedbackPointers()
        getTaskFeedbackPointers()
    },[])


    const getJiraAuthDetailsAndOpenModal = async () => {
        if(!projectName) { return }
        if(projectNameJira === projectName && (baseUrl !== '' && token !== '' && email !== '')) { 
            setShowJiraAuthDetailsModal(true)
            return 
        }

        const toastId = toast.info("Fetching auth details.", { autoClose: false})
        try {
            const response = await axiosInstance.get("/details", {
                params: {
                    project_name: projectName
                }
            })

            toast.dismiss(toastId)
            setShowJiraAuthDetailsModal(true)
            if(!response?.data) return

            setEmail(response.data.email)
            setBaseUrl(response.data.base_url)
            setToken(response.data.token)
            setProjectKey(response.data.project_key)
            setProjectNameJira(response.data.project_name)
        } catch(err) {
            toast.dismiss(toastId)
            toast.error(err?.response?.data?.detail || err?.message, { delay:1000 })
        }
    }

    const submitJiraAuthDetails = async () => {
        if(!baseUrl || !token || !email || !projectKey || isSubmittingJiraAuthDetails) return
        
        setIsSubmittingJiraAuthDetails(true)
        const toastId = toast.info("Submitting Details.", { autoClose: false})
        try {
            await axiosInstance.post("/details", {
                base_url: baseUrl,
                token: token,
                email: email,
                tool_type: 'jira',
                project_name: projectName,
                project_key: projectKey
            })

            setProjectNameJira(projectName)
            toast.dismiss(toastId)
            toast.success('Details have been submitted successfully.', { delay:1000 })
            setIsSubmittingJiraAuthDetails(false)
        } catch(err) {
            toast.dismiss(toastId)
            toast.error(err?.response?.data?.detail || err?.message, { delay:1000 })
            setIsSubmittingJiraAuthDetails(false)
        }
    }

    const closeModal = () => setShowJiraAuthDetailsModal(false)

    const handleCheckboxForAllTaskSelection = (isChecked) => {
        if(!isChecked) {
            setSelectedTasks({})
        }

        if(isChecked) {
            const selectedTasksObj = {}

            features.forEach(feature => {
                feature.tasks.forEach(task => {
                    selectedTasksObj[task.uuid] = task
                })
            })
            
            setSelectedTasks(selectedTasksObj)
        }

        setIsAllTaskSelected(isChecked)
    }




    const disableGenerateButton = !projectName || generating || (fragmentaryResponse && fragmentaryResponseProjectName && fragmentaryResponseProjectName === projectName && fragmentaryResponse?.summary?.composite_score < 7)
    return (
    <div>
        <div className="px-10">
            <div className="my-3 flex justify-end items-center gap-3">
                <AdminRoutes />
                <button  onClick={()=>navigateTo('/metric')} className="bg-transparent border border-black rounded px-6 py-2 text-black disabled:bg-[#f3f3f4] disabled:text-[#8D8A95] hover:bg-[#0D61F7] hover:border-[#0D61F7] hover:text-white">
                    Metric
                </button>

                <button disabled={!projectName || !epics} onClick={getJiraAuthDetailsAndOpenModal} className="bg-transparent border border-black rounded px-6 py-2 text-black disabled:bg-[#f3f3f4] disabled:text-[#8D8A95] disabled:border-[#8D8A95] hover:bg-[#0D61F7] hover:border-[#0D61F7] hover:text-white">
                    Jira Auth Details
                </button>
            </div>
            <div className="my-2 py-2">
                <div {...getRootProps({style})}>
                    <input {...getInputProps()} />
                    
                    <div className="flex flex-col justify-center items-center py-16">  
                        <div className="flex justify-center items-center">
                            <img src={UploadIcon} alt="Upload" />
                        </div>
                        <p className="text-black text-sm">Select a file or drag and drop here</p>
                        <p className="text-slate-400 text-sm">PDF is only supported at the moment</p>
                    </div>
                    
                </div>

                {file && <div className="bg-[#F2F2F2] relative mt-5 py-3 px-3 flex items-center gap-3">
                    <div className="h-12 w-12">
                        <img className="h-full w-full" src={PdfIcon} alt="Pdf" />
                    </div>
                    <div className="text-sm">{file.name}</div>
                    <button onClick={onRemoveFile} className="top-[-10px] right-[-10px] absolute bg-[#0D61F7] rounded-full h-6 w-6 flex items-center justify-center">
                        <img src={CloseIcon} alt="Remove" />
                    </button>
                </div>}

                {fragmentaryResponse && getFragmentaryResponse(fragmentaryResponse)}
            </div>
            
            <div className="flex gap-3 my-5">
                <div className="w-60"><input name="projectName" type="text" className="w-full border-b-2 py-4 focus:border-[#0D61F7]" placeholder="Project Name" onChange={(e)=>setProjectName(e.target.value)} value={projectName}/></div>
            </div>

            <div className="flex gap-3">
                <div className="w-60">
                    <select disabled={!projectName} name="LLM" className="w-full border-b-2 py-4 focus:border-[#0D61F7]" placeholder="Select LLM" onChange={(e)=>setLLM(e.target.value)} value={LLM}> 
                        {getOptions()}
                    </select>
                </div>
                <div>
                    <button  onClick={handleUploadFile} disabled={!file || !projectName || uploading} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {uploading ? 'Uploading...' : 'Upload'}
                    </button>
                </div>
                <div>
                    <button onClick={generate} disabled={disableGenerateButton} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {generating ? 'Processing...' : 'Ticket Generation'}
                    </button>
                </div>
            </div>

            <div className="flex my-1 items-center justify-end">
                <div>
                    <button onClick={checkResult} disabled={!projectName || checkingResult} className="bg-[#0D61F7] rounded-2xl px-6 py-4 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95]">
                        {checkingResult ? 'Checking result...' : 'Check Result' }
                    </button>
                </div>
            </div>

            {(epics) && epics.length > 0  && (
            <div className="flex flex-row gap-3">
                <div className="flex flex-col gap-3 mt-5 mb-5">
                    <div className="flex justify-between items-center bg-white sticky top-0 py-5">
                        <div className="text-base font-semibold">Epics: ({epics.length})</div>
                    </div>
                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        {showEpics(epics, addTask, activeEpicIndex)}
                    </div>
                </div>

                {activeEpicIndex !== -1 ? <div className="flex flex-col gap-3 mt-5 mb-5">
                    <div className="flex gap-3 items-center sticky top-0 pr-5 py-5 bg-white">
                        <div>
                            <input onChange={()=>handleCheckboxForAllTaskSelection(!isAllTaskSelected)} checked={isAllTaskSelected} id="task-selection" type="checkbox" className="z-[999999] h-6 w-6 accent-pink-300 md:accent-pink-500"/>
                        </div>
                        <div className="text-base font-semibold">Tasks: {tasksLength ? `(${tasksLength})` : ''}</div>
                        <div></div>
                    </div>

                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        {showTasksDashboard(features, showDetails, activeTaskIndex, activeEpicIndex, selectedTasks, setSelectedTasks, setIsAllTaskSelected)}
                    </div>
                </div> : <NoContentContainer text="Click on the Epic to see task list." />}

                {(activeTaskIndex !== -1) ?<div className="flex flex-1 flex-col gap-3 mt-5 mb-5">
                    <div className="flex justify-between items-center pr-5 py-5 bg-white sticky top-0">
                        <div className="text-base font-semibold">Details:</div>

                        <AddToJiraButton 
                            isAllTaskSelected={isAllTaskSelected} 
                            selectedTasks={selectedTasks} 
                            isMissingAuthDetails={!email || !token || !baseUrl || !projectKey}
                            epicDetails={epics[activeEpicIndex]}
                            projectName={projectName}
                        />
                    </div>

                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        <div className="flex flex-col flex-wrap">
                            <TaskDetails ticket={details} activeTaskIndex={activeTaskIndex} activeEpicIndex={activeEpicIndex}/>
                        </div>

                        <Feedback projectName={projectName} type={FEEDBACK_TYPES[1]} type_uuid={details.uuid} feedbackPointers={taskFeedbackPointers}/>
                    </div>
                    
                </div> : activeEpicIndex !== -1 && (
                        <div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
                        <div className="flex justify-between items-center pr-5 py-5 bg-white">
                            <div className="text-base font-semibold"></div>

                            <AddToJiraButton 
                                isAllTaskSelected={isAllTaskSelected} 
                                selectedTasks={selectedTasks} 
                                isMissingAuthDetails={!email || !token || !baseUrl || !projectKey}
                                epicDetails={epics[activeEpicIndex]}
                                projectName={projectName}
                            />
                        </div>
                        <div className="mt-5 border border-dashed flex justify-center flex-1 px-3 overflow-x-auto">
                            <Feedback projectName={projectName} type={FEEDBACK_TYPES[0]} type_uuid={epics[activeEpicIndex].uuid} feedbackPointers={epicFeedbackPointers}/>
                        </div>
                    </div> 
                    )}

            </div>
            )}
        </div>
        
        {showJiraAuthDetailsModal && <Modal>
            <ModalComponentWrapper>
                <ModalComponent 
                    baseUrl={baseUrl}
                    token={token}
                    email={email}
                    projectKey={projectKey}
                    setEmail={setEmail}
                    setBaseUrl={setBaseUrl}
                    setToken={setToken}
                    setProjectKey={setProjectKey}
                    submitJiraAuthDetails={submitJiraAuthDetails}
                    closeModal={closeModal}
                    isSubmitting={isSubmittingJiraAuthDetails}
                />
            </ModalComponentWrapper>
        </Modal>}
    </div>)
}


const CheckBox = ({label, htmlFor, checked, selectedFeedbackPointers, setSelectedFeedbackPointers, index}) => {
    
    const onChange = (e) => {
        setSelectedFeedbackPointers({
            ...selectedFeedbackPointers,
            [index]: !checked
        })
    }
   
    return (
        <div className="flex items-center">
            <label className="relative flex items-center p-3 rounded-full cursor-pointer" htmlFor={htmlFor}>
                <input type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-500 before:opacity-0 before:transition-opacity checked:border-green-500 checked:bg-green-500 checked:before:bg-green-500 hover:before:opacity-10"
                id={htmlFor} 
                checked={checked}
                onChange={onChange}
                />
                <span
                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-3.5 w-3.5" viewBox="0 0 20 20" fill="currentColor"
                    stroke="currentColor" strokeWidth="1">
                    <path fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"></path>
                </svg>
                </span>
            </label>
            
            <label className="mt-px font-light text-gray-700 cursor-pointer select-none" htmlFor={htmlFor}>
                {label}
            </label>
        </div> 
    )
}

const showFeedbackPointer = (feedbackPointers, setSelectedFeedbackPointers, selectedFeedbackPointers) => {
    if(!feedbackPointers) return null

    return feedbackPointers.map((feedbackPointer, idx) => (
    <div>
        <CheckBox key={feedbackPointer.uuid} index={idx} label={feedbackPointer.text} htmlFor={feedbackPointer.uuid} checked={selectedFeedbackPointers[idx] === true} setSelectedFeedbackPointers={setSelectedFeedbackPointers} selectedFeedbackPointers={selectedFeedbackPointers}/>
    </div>))
}

const Feedback = ({type, type_uuid, projectName, feedbackPointers}) => {
    const axiosInstance = AxiosInstance.getInstance()
    const [selectedFeedbackPointers, setSelectedFeedbackPointers] = useState({})
    const [submitting, setSubmitting] = useState(false)

    const [text, setText] = useState('')

    const getPointers = (selectedFeedbackPointers) => {
        const pointers = []
        Object.keys(selectedFeedbackPointers).forEach(key => {
            if(selectedFeedbackPointers[key]) {
                const pointer = feedbackPointers[Number(key)]
                pointers.push({
                    uuid: pointer.uuid,
                    text: pointer.text
                })
            }
        })

        return pointers
    }

    const clearField = () => {
        setText('')
        setSelectedFeedbackPointers({})
    }

    const submitFeedback = async () => {
        try {
            setSubmitting(true)
            const requestBody = {
                text: text,
                type: type,
                type_uuid: type_uuid,
                project_name: projectName,
                pointers: getPointers(selectedFeedbackPointers)
            }

            await axiosInstance.post("/feedback", requestBody)

            toast.success("Feedback has been submitted.")
            setSubmitting(false)
            clearField()
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setSubmitting(false)
        } 
    }

    const hasSelectedPointer = () => {
        const keys = Object.keys(selectedFeedbackPointers)
        for(const key in keys) {
            if(selectedFeedbackPointers[key]) {
                return true
            } 
        }

        return false;
    }

    const shouldBeDisabled = () => {
        const hasValue = hasSelectedPointer() || text.trim() !== ''
        return !hasValue
    }

    return (
        <div className="mt-5 w-full">
            <div className="text-base font-semibold capitalize">{type} Feedback:</div>
            <div className="flex flex-col gap-5">

               <div className="flex flex-col">                   
                    {showFeedbackPointer(feedbackPointers, setSelectedFeedbackPointers, selectedFeedbackPointers)}
               </div>

                <div className="relative w-full min-w-[10px]">
                    <textarea
                    value={text}
                    onChange={e => setText(e.target.value)}
                    className="peer h-full min-h-[150px] w-full resize-none border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-light text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=" ">
                    </textarea>
                    <label
                    className="after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-light leading-tight text-blue-gray-500 transition-all after:absolute after:-bottom-0 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-[#ff5c00] after:transition-transform after:duration-300 peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.25] peer-placeholder-shown:text-blue-gray-500 peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-[#ff5c00] peer-focus:after:scale-x-100 peer-focus:after:border-[#ff5c00] peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Additional Text:
                    </label>
                </div>

                <div className="flex items-center justify-end">
                    <button disabled={ shouldBeDisabled() || submitting}  onClick={submitFeedback} className="bg-[#ff5c00] border border-[#ff5c00] rounded px-6 py-2 text-white disabled:bg-[#f3f3f4] disabled:text-[#8D8A95] disabled:border-[#8D8A95]">
                        {submitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>

            </div>
        </div>
    )
}


export default Dashboard