import { useEffect, useState } from "react"
import AxiosInstance from '../../lib/axios/axios-instance';
import {  toast } from 'react-toastify';
import { updateAccessGranted } from "../../lib/redux/slices/auth-user.slice";
import { useDispatch } from "react-redux";

const axiosInstance = AxiosInstance.getInstance()

const showPointersText = (dataList) => {
    if(!Array.isArray(dataList)) return (<li>{dataList}</li>)
    return dataList.map(( pointer, idx ) => {
        const {text} = JSON.parse(pointer)

        return <li key={idx}>{text}</li>
    })
  }

const FeedbackAccordion = ({showDetails, toggle, feedback}) => {
    const { pointers, user_id, text } = feedback
    return (
        <div className="bg-[#F2F2F2] relative mt-5 flex flex-col justify-center gap-3">
            <div onClick={toggle} role="button" className="py-3 px-3 max-w-[45vw] truncate text-base font-semibold">
                {user_id} : {text ? text : ''}
             </div>

            {showDetails && (<div className="py-3 px-3 flex flex-col gap-3">

                {pointers.length > 0 && <div >
                    <ol className="pl-4 text-sm font-light list-decimal">
                        {showPointersText(pointers)}
                    </ol>
                </div>}

                {text && 
                <div >
                    <div className="font-medium text-base text-[#828282]">Additional Text:</div>
                    <div className="text-sm font-light list-decimal">
                        {text}
                    </div>
                </div>
                }

            {text && 
                <div >
                    <div className="font-medium text-base text-[#828282]">User Id:</div>
                    <div className="text-sm font-light list-decimal">
                        {user_id}
                    </div>
                </div>
                }
            
            </div>)}
        </div>
    )
}


const showUsers = (users, setUser, activeUserIndex) => {
    if(!users) return null

    return users.map((user, index) => <User key={index} setUser={setUser} user={user} index={index} activeUserIndex={activeUserIndex}/>)
}

const showProjects = (projects, userId) => {
    if(!projects) return null

    return projects.map((project, index) => <Project key={index} project={project} index={index} userId={userId}/>)
}

const Project = ({ project, index, userId}) => {
    const { project_name } = project
 
    const handleClick = () => {
        let href = window.origin + `/admin/feedback?project_name=${project_name}&user_id=${userId}`
        window.open(href, '_blank', 'nofollow');
    }

    const backgroundColor = '#FAFAFA'
    const borderColor = '#0D61F7'
    const divBorderColor = '#E3E3E3'
    return (
    <div role="button" style={{backgroundColor: backgroundColor, borderColor: divBorderColor, transition: 'border-color .3s ease-out'}} className="shadow-lg w-full border py-4" onClick={handleClick}>

        <div style={{borderColor: borderColor, transition: 'border-color .3s ease-out'}} className="border-l-4 px-3 flex flex-col gap-1">
            <div className="font-medium text-base">{project_name}</div>
        </div>

    </div>
    )
}

const User = ({setUser, user, index, activeUserIndex}) => {
    const { username } = user

    const handleClick = () => {
        setUser(index)
    }

    const backgroundColor = activeUserIndex === index ? '#f2f2f2' : '#FAFAFA'
    const borderColor = activeUserIndex === index ? '#ff5c00' : '#0D61F7'
    const divBorderColor = activeUserIndex === index ? '#ff5c00' : '#E3E3E3'
    return (
    <div role="button" style={{backgroundColor: backgroundColor, borderColor: divBorderColor, transition: 'border-color .3s ease-out'}} className="shadow-lg border py-4" onClick={handleClick}>

        <div style={{borderColor: borderColor, transition: 'border-color .3s ease-out'}} className="border-l-4 px-3 flex flex-col gap-1">
            <div className="font-medium text-base">{username}</div>
        </div>

    </div>
    )
}

const UserDetails = ({details, getUsersDetails}) => {
    const dispatch = useDispatch()

    const {username, access_granted, rate_limit_info, user_type, _id} = details
    const [rateLimit, setRateLimit] = useState(rate_limit_info?.rate_limit ? rate_limit_info?.rate_limit : 0)

    useEffect(() => {
        setRateLimit(rate_limit_info?.rate_limit)
    }, [rate_limit_info?.rate_limit])

    const updateUserAccess = async () => {
        try {
            await axiosInstance.put(`/admin/users/${_id}`,{
                ...details,
                access_granted: !access_granted
            })
            
            dispatch(updateAccessGranted({
                accessGranted: !access_granted,
                userId: _id
            }))
            toast.success("User updated successfully.")
            getUsersDetails()
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)

        } 
    }

    const updateRateLimit = async () => {

        try {
            await axiosInstance.put(`/admin/users/${_id}`,{
                ...details,
                rate_limit_info: {
                    ...rate_limit_info,
                    rate_limit: Number(rateLimit)
                }
            })
            toast.success("User updated successfully.")
            getUsersDetails()
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)

        } 
    }

    const onChangeRateLimit = (e) => {
        setRateLimit(e.target.value)
    }

    const isRateLimitNumber = /^\d+$/.test(rateLimit)
    return (            

              <div className="bg-white text-left shadow-xl transition-all">
                
                <div className="bg-white pt-1">
                <div className="flex items-center justify-between"> 
                <div className="flex items-center justify-start gap-2 pl-3">
                    {/* <div className="bg-[#EAD9F8] text-[#913CB9] text-xs font-bold px-2 py-1 rounded">{Number(activeEpicIndex + 1) + '.' + Number(activeTaskIndex + 1)}</div> */}
        
                    {user_type && <div className="bg-[#D9F4F8] text-[#268FB0] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Feature</div> */}
                        <div className="text-xs font-bold uppercase">{user_type}</div>
                    </div>}

                    {access_granted ? <div className="bg-[#CDF4DD] text-[#188544] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Feature</div> */}
                        <div className="text-xs font-bold">Active</div>
                    </div> : <div className="bg-[#ff000059] text-[#ff0000] px-2 py-1 rounded">
                        {/* <div className="text-[9px]">Feature</div> */}
                        <div className="text-xs font-bold">Inactive</div>
                    </div>}
        
                    
                </div>
                </div>
        
                {/* <div className="border-l-4 border-[#ff5c00] px-3">
                    <div className="font-medium text-lg">{username}</div>
                    <div className="font-light text-sm text-[#828282]">{description}</div>
                </div> */}
        
                <div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Username:</div>
                    <div className="text-sm font-light">{username}</div>
                </div>

                <div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Today's Request Count:</div>
                    <div className="text-sm font-light">{rate_limit_info?.request_count}</div>
                </div>

                <div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Last Requested Date:</div>
                    <div className="text-sm font-light">{new Date(rate_limit_info?.last_request_date).toString()}</div>
                </div>

                <div className="px-4 pt-4">
                    <div className="font-medium text-base text-[#828282]">Total Request Count:</div>
                    <div className="text-sm font-light">{rate_limit_info?.total_request_count}</div>
                </div>
        
                <div className="px-4 pt-4 flex gap-3 items-end">
                    <div>
                        <div className="font-medium text-base text-[#828282]">Limitation:</div>
                        <input onChange={onChangeRateLimit} value={rateLimit ? rateLimit : 0} className="text-sm font-light border-b focus:border-[#FF5C00]" type="number" placeholder="limitation" />
                    </div>
                    <div><button disabled={!isRateLimitNumber} onClick={updateRateLimit} className="bg-[#FFECE1] disabled:opacity-50 text-[#FF5C00] hover:border hover:border-[#FF5C00] text-sm font-bold px-2 py-1 rounded">Update</button></div>
                </div>
                
                <div className="bg-gray-50">
                    <div className="border-t-[#E3E3E3] border-t mx-3 mt-3 flex items-center justify-between ">
                        <div></div>
                        <div className="pt-3 pb-2">
                            {access_granted 
                                ? <button onClick={updateUserAccess} className="bg-[#ff000059] text-[#ff0000] text-sm font-bold px-2 py-1 rounded">Deactivate User</button>
                                : <button onClick={updateUserAccess} className="bg-[#CDF4DD] text-[#188544] text-sm font-bold px-2 py-1 rounded">Activate User</button>
                            }
                        </div>
                    </div>
                </div>
                </div>
        
              </div>          
)
}

const DisplayMessage = ({text}) => {
    return (
        <div className="min-w-72">
            <div style={{height: '72vh'}} className="flex flex-1 flex-col gap-3 mb-5 sticky top-0">
                <div className="border border-dashed flex justify-center flex-1">
                    <div className="mt-16">{text}</div>
                </div>
            </div> 
        </div>
    )
}

const AdminDashboard = () => {
    const [users, setUsers] = useState(null)
    const [projects, setProjects] = useState(null)
    const [activeUserIndex, setActiveUserIndex] = useState(-1)
    const [projectLoading, setProjectLoading] = useState(false)
    const [gettingUsers, setGettingUsers] = useState(false)

    const getUsersDetails = async () => {
        setGettingUsers(true)
        try {

            const response = await axiosInstance.get(`/admin/users`)

            setUsers(response?.data)
            setGettingUsers(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setGettingUsers(false)
        } 
    }

    const getProjectDetails = async () => {
        if(activeUserIndex === -1) return

        setProjects(null)
        setProjectLoading(true)
        try {
            const response = await axiosInstance.get(`/admin/project-list`,{
                params: {
                  user_id: users[activeUserIndex]?._id
                }
              })
            setProjects(response?.data)
            setProjectLoading(false)
        } catch(err) {
            console.error(err)
            toast.error(err?.response?.data?.detail || err?.message)
            setProjectLoading(false)
            setProjects(null)
        } 
    }

    useEffect(() => {
        getUsersDetails()
    },[])

    useEffect(() => {
        getProjectDetails()
    },[activeUserIndex])


    const setUser = (index) => {
        setActiveUserIndex(index)
    }


    const showProjectList = () => {
        if(activeUserIndex === -1) return null

        

        const displayProjectList = () => {
            if(!projects) return <DisplayMessage text="No project found." />
    
            if(!projects.length) return <DisplayMessage text="Project list is Empty." />

            return (<div className="h-[90vh] overflow-x-auto flex flex-col gap-3">
                {showProjects(projects, users[activeUserIndex]?._id)}
                </div>
            )
        }

    
        return (
            <div className="flex flex-col gap-3 mt-5 mb-5 min-w-72">
                <div className="flex justify-between items-center sticky top-0 pr-5 py-5 bg-white">
                    <div className="text-base font-semibold">Projects: {projects ? `(${projects.length})` : ''}</div>
                </div>
                {displayProjectList()}
            </div>
        )
    }

    const showUserDetails = (users, activeUserIndex, getUsersDetails) => {
        if(activeUserIndex === -1) return null

        return (
            <div className="flex flex-col flex-1 gap-3 mt-5 mb-5">
                <div className="flex justify-between items-center pr-5 py-5 bg-white sticky top-0">
                    <div className="text-base font-semibold">User Details:</div>
                    <div></div>
                </div>

                <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                    <div className="flex flex-col flex-wrap">
                        <UserDetails details={users[activeUserIndex]} getUsersDetails={getUsersDetails}/>
                    </div>
                </div>
            </div> 
        )
    }

    if(gettingUsers) {
        return (<div className="flex flex-1 flex-col gap-3 mt-5 mb-5 sticky top-0 h-[97vh]">
            <div className="mt-5 flex justify-center flex-1">
                <div className="mt-16">Loading...</div>
            </div>
        </div> )
    }

    return (
        <div className="px-10">
            <div className="flex flex-row gap-3">
                <div className="flex flex-col gap-3 mt-5 mb-5 min-w-72">
                    <div className="flex justify-between items-center bg-white sticky top-0 py-5">
                        <div className="text-base font-semibold">Users: ({users && users.length})</div>
                    </div>
                    <div className="max-h-[97vh] overflow-x-auto flex flex-col gap-3">
                        {showUsers(users, setUser, activeUserIndex)}
                    </div>
                </div>


                {showProjectList()}

                {showUserDetails(users, activeUserIndex, getUsersDetails)}

            </div>
        </div>
    )
}

export default AdminDashboard